import styles from "./CompanyPageReviewCard.module.css";
import { LikeIcon2 } from "../LikeIcon2/LikeIcon2";
import { useState } from "react";
import { getUserAvatar, getUserLongName, getUserSlug } from "../../utils";
import { ReviewStars } from "../ReviewStars/ReviewStars";
import { Link } from "react-router-dom";

export const CompanyPageReviewCard = ({ review }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleClick = () => {
        setIsChecked(!isChecked)
    }

    const date = new Date(review.created_at);

    const formattedDate = date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    });

    return (
        <div className={styles['review']}>
            <div className={styles['review-details']}>

                <div className={styles['user-avatar']}>
                    <div className={styles['bg-icon']}></div>
                    <div style={{ backgroundImage: `url('${getUserAvatar(review.author, false)}')` }} className={styles['avatar-img']} />
                </div>

                <div className={styles['name-and-star']}>
                    <Link to={`/user/${getUserSlug(review.author)}`}><h3>{getUserLongName(review.author)}</h3></Link>
                    <div className={styles['stars']}>
                        <ReviewStars rating={review.rating} />
                    </div>
                </div>

                <div className={styles['date-and-count']}>
                    <span className={styles['date']}>Posted on {formattedDate}</span>
                    <span className={styles['count']}>1 Review</span>
                </div>

            </div>

            <div className={styles['review-content']}>
                <div className={styles['line']}></div>
                <div>
                    <h4>{review.title}</h4>
                    <p>{review.review}</p>
                </div>

                <div className={styles['review-like']} onClick={handleClick}>
                    <LikeIcon2 fill={isChecked ? '#FF8A8C' : 'black'} />
                </div>
            </div>
        </div>
    );
}