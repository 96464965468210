import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./LoginPage.module.css";
import { TopNav } from "../../components/TopNav/TopNav";
import { Footer } from "../../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { loginAsync } from "../../actions/auth.action";
import { LockIcon } from "../../components/LockIcon/LockIcon";
import { UserIcon } from "../../components/UserIcon/UserIcon";

export const LoginPage = () => {
  const initialState = {
    email: '',
    password: '',
  }
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    dispatch(loginAsync(state, (user) => {
      console.log(user)
      setState(initialState)
      navigate('/')
    }))
  }

  useEffect(() => {
    document.title = "Cloud Reputation - Login";
  }, []);

  return (
    <>

      <TopNav bg="filled" />

      <div className={styles['login-form']}>

        <form>

          <div className={styles['form-group']}>
            <div className={styles['icon']}>
              <UserIcon />
            </div>
            <input type="email" name="email" value={state.email} onChange={onChange} placeholder="Email ID" />
          </div>

          <div className={styles['form-group']}>
            <div className={styles['icon']}>
              <LockIcon />
            </div>
            <input type="password" name="password" value={state.password} onChange={onChange} placeholder="Password" />
          </div>

          <div className={styles['forgot-password']}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>

          <div className={styles['login-now']}>
            <button onClick={onSubmit}>Login Now</button>
          </div>



        </form>
      </div>

      <Footer />
    </>
  );
};
