export const PaginationPrevIcon = ({disabled}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
            <path d="M27 4.5C24.0453 4.5 21.1194 5.08198 18.3896 6.21271C15.6598 7.34344 13.1794 9.00078 11.0901 11.0901C6.87053 15.3097 4.5 21.0326 4.5 27C4.5 32.9674 6.87053 38.6903 11.0901 42.9099C13.1794 44.9992 15.6598 46.6566 18.3896 47.7873C21.1194 48.918 24.0453 49.5 27 49.5C32.9674 49.5 38.6903 47.1295 42.9099 42.9099C47.1295 38.6903 49.5 32.9674 49.5 27C49.5 24.0453 48.918 21.1194 47.7873 18.3896C46.6566 15.6598 44.9992 13.1794 42.9099 11.0901C40.8206 9.00078 38.3402 7.34344 35.6104 6.21271C32.8806 5.08198 29.9547 4.5 27 4.5ZM18 18H22.5V36H18M36 18V36L24.75 27" fill="url(#paint0_linear_126_548)"  fillOpacity={!disabled ? 1 : 0.46} />
            <defs>
                <linearGradient id="paint0_linear_126_548" x1="27" y1="4.5" x2="27" y2="49.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF9A62" />
                    <stop offset="1" stop-color="#FF8A8C" />
                </linearGradient>
            </defs>
        </svg>
    );
}