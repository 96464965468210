import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLandingDataAsync } from "../../actions/general.action";
import styles from "./Homepage.module.css";
import clsx from "clsx";
import { KeyFeature1 } from "../../components/KeyFeature1/KeyFeature1";
import { KeyFeature2 } from "../../components/KeyFeature2/KeyFeature2";
import { KeyFeature3 } from "../../components/KeyFeature3/KeyFeature3";
import { KeyFeature4 } from "../../components/KeyFeature4/KeyFeature4";
import { HowItWorksBulletIcon } from "../../components/HowItWorksBulletIcon/HowItWorksBulletIcon";
import { Link } from "react-router-dom";
import { HomePageReviewCard } from "../../components/HomePageReviewCard/HomePageReviewCard";
import { TopNav } from "../../components/TopNav/TopNav";
import { Footer } from "../../components/Footer/Footer";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/splide.min.css';
import { SliderPrevIcon } from "../../components/SliderPrevIcon/SliderPrevIcon";
import { SliderNextIcon } from "../../components/SliderNextIcon/SliderNextIcon";

function splitIntoPairs(arr) {
  const pairs = [];
  for (let i = 0; i < arr.length; i += 2) {
    pairs.push(arr.slice(i, i + 2));
  }
  return pairs;
}

export const Homepage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLandingDataAsync(() => {
      console.log("Homepage data loaded")
    }))
  }, []);

  useEffect(() => {
    document.title = "Cloud Reputation - Dive into the Wisdom of Customer Tales";
  }, []);

  const { latestReviews } = useSelector(({ General }) => General.LandingPage)

  const inPairs = latestReviews ? splitIntoPairs(latestReviews) : []

  return (
    <>
      <div className={styles['sticky-banner']}>
        <Link to="/">
          <img src="/img/homepage-banner.png" alt="Homepage Banner" />
        </Link>
      </div>

      <TopNav />

      <div className={clsx(styles['banner'])}>
        <div className={styles['left']}>
          <h1>
            Discover<br />
            Compare<br />
            Decide
          </h1>
          <p>Discover top cloud services with real reviews & expert ratings.</p>
        </div>
        <div className={styles['right']}>
          <img src="/img/banner-image.png" alt="" />
        </div>
      </div>

      <div className={clsx(styles['key-features'])}>
        <h2>Our Key Features</h2>
        <div className={styles['boxes']}>
          <div className={clsx(styles['box'], styles['primary'])}>
            <div className={styles['box-head']}>
              <KeyFeature1 />
              <h3>Verified Reviews</h3>
            </div>
            <div className={styles['box-content']}>
              Access genuine feedback from real users to ensure you're making informed decisions.
            </div>
          </div>
          <div className={styles['box']}>
            <div className={styles['box-head']}>
              <KeyFeature2 />
              <h3>Expert Ratings</h3>
            </div>
            <div className={styles['box-content']}>
              Compare cloud services with expert insights on performance, security, and pricing.
            </div>
          </div>
          <div className={styles['box']}>
            <div className={styles['box-head']}>
              <KeyFeature3 />
              <h3>User-Friendly Interface</h3>
            </div>
            <div className={styles['box-content']}>
              Quickly search, filter, and navigate through reviews to find the best options in minutes.
            </div>
          </div>
          <div className={styles['box']}>
            <div className={styles['box-head']}>
              <KeyFeature4 />
              <h3>Up-to-Date Information</h3>
            </div>
            <div className={styles['box-content']}>
              Stay updated with the latest cloud reviews and tech insights.
            </div>
          </div>
        </div>
        <div className={styles['floating-feature-image']}>
          <img src="/img/Vector (3).png" alt="Feature Blob" />
        </div>

        <div className={styles['floating-dot-1']}></div>
      </div>

      <div className={clsx(styles['how-it-works'])}>
        <div className={styles['heading']}>
          <h2>How It Works?</h2>
        </div>
        <div className={styles['contents']}>
          <div className={styles['list']}>
            <div className={styles['item']}>
              <HowItWorksBulletIcon />
              <span className={styles['point']}>Search Cloud Services</span>
            </div>
            <div className={styles['item']}>
              <HowItWorksBulletIcon />
              <span className={styles['point']}>Read Verified Reviews</span>
            </div>
            <div className={styles['item']}>
              <HowItWorksBulletIcon />
              <span className={styles['point']}>Compare & Choose</span>
            </div>
            <div className={styles['item']}>
              <HowItWorksBulletIcon />
              <span className={styles['point']}>Share Your Experience</span>
            </div>
          </div>
        </div>
        <div className={styles['floating-img']}>
          <img src="/img/Group 8.png" alt="" />
        </div>

        <div className={styles['floating-dot-1']}></div>
        <div className={styles['floating-dot-2']}></div>
        <div className={styles['floating-dot-3']}></div>
        <div className={styles['floating-dot-4']}></div>
      </div>

      <div className={clsx(styles['brands'])}>
        <div className={styles['left']}>
          <div className={styles['tagline']}>Customer-Focused Solution</div>
          <h2>
            250+ <br />
            Brands rely on <br />
            Cloud Reputation.io
          </h2>
          <p>
            Join 10,000+ brands that trust CloudReputation.io for cloud-based review management. From startups to global leaders, our platform adapts to your needs, helping you build, protect, and grow your online reputation. With tailored plans and innovative tools, we empower businesses to collect authentic feedback and boost their brand presence—all in one place. Your success is our mission.
          </p>
        </div>
        <div className={styles['right']}>
          <img src="/img/73f90dd75c1d2cc53707733700ffffbe.gif" alt="" />
        </div>
      </div>

      {
        inPairs.length
          ?
          <div className={clsx(styles['customer-voices'])}>

            <div className={styles['title']}>
              <h2>Customer Voices</h2>
              <div>Explore the experiences of users who’ve relied on our reviews to make better decisions.</div>
              <img src="/img/Group 9.png" alt="Customer Voices Animation" />
            </div>

            <div className={styles['reviews']}>
              <Splide
                options={{
                  perPage: 1,
                  perMove: 1,
                  pagination: false,
                }}
                aria-label="My Favorite Images"
                hasTrack={false}
              >
                <SplideTrack>
                  {
                    inPairs.map((reviews, index) => {
                      return (
                        <SplideSlide>
                          {reviews[0] && <HomePageReviewCard className={styles['review-box']} review={reviews[0]} position={'right'} />}
                          {reviews[1] && <HomePageReviewCard className={styles['review-box']} review={reviews[1]} position={'left'} />}
                        </SplideSlide>
                      )
                    })
                  }
                </SplideTrack>

                <div className="splide__arrows">
                  <button className={clsx('splide__arrow', 'splide__arrow--prev', styles['splide-nav-btn'])}>
                    <SliderPrevIcon />
                  </button>
                  <button className={clsx('splide__arrow', 'splide__arrow--next', styles['splide-nav-btn'])}>
                    <SliderNextIcon />
                  </button>
                </div>
              </Splide>
            </div>

          </div>
          :
          null
      }

      <Footer />
    </>
  );
};
