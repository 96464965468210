.company {
  background: #FFF;

  padding: 83px 156.34px 50px 186px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
}

.company .floating-dot-1 {
  position: absolute;
  background-color: rgba(255, 188, 148, 0.29);
  width: 24.7px;
  height: 24.7px;
  left: 10px;
  bottom: 34px;
  border-radius: 100%;
}

.company .floating-dot-2 {
  position: absolute;
  background-color: rgba(255, 188, 148, 0.29);
  width: 24.7px;
  height: 24.7px;
  left: 426px;
  top: 26px;
  border-radius: 100%;
}

.company .floating-dot-3 {
  position: absolute;
  background-color: rgba(255, 188, 148, 0.29);
  width: 24.7px;
  height: 24.7px;
  right: 1px;
  bottom: 42px;
  border-radius: 100%;
}

.company-logo {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 147px;
  width: 190px;

  background-image: url('../../../static/img/Ellipse 119.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.company-logo img {
  height: calc(100% - 30px);
}

.company .top-tier {
  padding: 85px 28.66 85px 57px;
  background-image: url('../../../static/img/Ellipse\ 120.png');
  background-size: contain;
  background-repeat: no-repeat;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.company .center {
  max-width: 590px;
}

.company .center h1 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.company .center .review-summary {
  display: flex;
  justify-content: space-between;
  gap: 52px;

  margin-top: 20px;
}

.company .center .review-rating {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.company .center .review-count {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.company .center .website {
  margin-top: 40px;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}



.company .center .website span:first-child {
  margin-right: 27px;
}

.company .center .website span:last-child {
  text-decoration-line: underline;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.review-box {
  display: flex;
  align-items: flex-start;

  gap: 182px;
  padding-top: 140px;
  padding-left: 122px;
  padding-right: 122px;

  background: linear-gradient(88deg, #F9EDE5 -124.49%, #F5F5F5 227.99%);

  /* max-width: 1196px; */


  /* padding: 0px 83px 0px 189px; */
}

.write-review {
  display: flex;
  flex-direction: column;

  flex: 1;

  position: relative;
}

.floating-write-icon {
  position: absolute;
  top: -6.5px;
  left: -50px;

  padding: 25px 19px;

  border-radius: 100%;

  background-color: #FFF;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-400);
}

.floating-lady {
  position: absolute;
  top: 0px;
  right: -124px;
}

.floating-lady img {
  height: 372px;
}

.write-review .heading-box {
  display: flex;
  justify-content: space-between;

  background-color: #FFF;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-400);

  padding: 26.5px 19.32 26.5px 73px;
}

.write-review .heading-box h2 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.write-review .heading-box div {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.write-review input {
  margin-top: 46px;
  padding: 34px 46px;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  outline: 0px;
  border-width: 0px;
}

.write-review textarea {
  margin-top: 46px;
  padding: 34px 46px;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  outline: 0px;
  border-width: 0px;
  resize: none;
}

.write-review button {
  align-self: flex-end;

  background-image: url('../../../static/img/Rectangle 3368 (1).png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding: 40px 23px 23px 84px;
  border-width: 0px;

  margin-top: 11px;

  opacity: 0.5;
}

.review-stats {
  border-radius: 0px 0px 25px 25px;
  background: #FFF;

  padding: 24px 21.5px 26.94px;

  position: relative;
}

.review-stats h3 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  margin-bottom: 40.83;
}

.review-stats .review-rows {
  display: flex;
  flex-direction: column;
  gap: 31px;
}

.review-stats .review-row {
  display: flex;
  gap: 35px;
  align-items: center;
}

.review-stats .review-row .review-number {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 15.63px;
}

.review-stats .review-row .review-stars {
  display: flex;
  gap: 10px;
}

.review-stats .review-row .review-percent {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 42.8px;
}

.review-stats .floating-pin {
  position: absolute;
  top: -43px;
  left: calc(50% - 25px);
}

.reviews-container {
  /* max-width: 1196px; */
  padding-top: 115px;
  padding-left: 122px;
  padding-right: 122px;
  padding-bottom: 225px;

  display: flex;
  gap: 78px;

  background: linear-gradient(88deg, #F9EDE5 -124.49%, #F5F5F5 227.99%);
}

.reviews-container .filter-box {
  border-radius: 20px;
  background: #FFF;
  padding: 25px 33px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.filter {
  display: flex;
  align-items: center;
  gap: 39px;
}

.sort {
  display: flex;
  align-items: center;
  gap: 6px;
}

.review-list {
  margin-top: 165.25px;
  display: flex;
  flex-direction: column;
  gap: 191.16px;
}

.about-and-faq {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-heading {
  padding: 19px 28px;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;

  margin-bottom: 25px;

  text-align: center;

  border-radius: 200%;
  background: linear-gradient(90deg, rgba(255, 189, 146, 0.40) 0%, rgba(255, 148, 141, 0.40) 100%);
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400) inset;
}

.about-container {
  padding: 32px;

  margin-bottom: 172px;

  position: relative;
}

.about-half-circle-con {
  position: absolute;

  top: 0px;
  left: 0px;
  height: calc(50% + 10px);
  width: 100%;

  overflow: hidden;
}

.about-half-circle-con div {
  height: 200%;
  border: 10px solid rgba(255, 138, 140, 0.25);
  border-radius: 100%;
  width: calc(100% - 10px);
  margin: 5px;
}

.about-half-circle-con::before {
  content: " ";
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(100% - 20px);
  left: 1px;
  background-color: rgba(255, 138, 140, 1);
  border-radius: 100%;
}

.about-half-circle-con::after {
  content: " ";
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(100% - 20px);
  right: 1px;
  background-color: rgba(255, 138, 140, 1);
  border-radius: 100%;
}

.about {
  width: 391px;
  height: 391px;
  flex-shrink: 0;

  /* padding: 32px; */

  border-radius: 391px;
  background: #FFF;
  padding-bottom: 20px;

  /* Drop Shadow/600 */
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);



  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.about>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about .icon {
  background-image: url(../../../static/img/ef8de7d11ced2e2b6f04b8b4934c6f30.png);
  background-size: cover;
  width: 78px;
  height: 78px;
  flex-shrink: 0;
}

.about .about-text {
  color: #000;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  padding: 0px 45px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 7;
  overflow: hidden;
}

.about h4 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
  margin-top: 21px;
}

.about .contact-text {
  color: #000;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  margin-top: 6px;

  padding: 0px 94px;
}

.faq {
  position: relative;
}

.faq-box {
  position: relative;
  border-radius: 0px 66px;
  background: #FFF;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);

  padding: 23px 16px 55px 16px;

  width: 436px;
  flex-shrink: 0;

  z-index: 1;
}

.faq-bottom-ellipse {
  position: absolute;
  width: 190px;
  height: 147px;
  right: -67px;
  bottom: -77px;
  border-radius: 100%;
  background: linear-gradient(90deg, rgba(255, 189, 146, 0.40) 0%, rgba(255, 148, 141, 0.40) 100%);
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400) inset;
  z-index: 0;
}

.faq h3 {
  color: #000;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.faq-items {
  display: flex;
  flex-direction: column;
  gap: 49px;

  margin-top: 41px;


}

.faq-item {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  /* 23.034px */

  position: relative;
  padding-right: 41px;
  padding-left: 34px;
}

.faq-item::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #FF9A62;
  border-radius: 100%;
  left: 0px;
  top: 0px;
}

.faq-item::after {
  content: "";
  position: absolute;
  border-left: 2px solid #000000;
  height: calc(100% + 29px);
  left: 9px;
  top: 20px;
}

.faq-item:last-child::after {
  height: 0px;
}

.pagination {
  margin-top: 149.63px;
}