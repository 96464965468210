import clsx from "clsx";
import styles from "./TopNav.module.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserAvatar, getUserLongName } from "../../utils";

export const TopNav = ({ bg = 'transparent' }) => {
    const { user } = useSelector(({ Auth }) => Auth);
    const location = useLocation();

    const isLogin = location.pathname === '/login';

    console.log(user)

    return (
        <div style={{ background: bg === 'transparent' ? 'transparent' : 'linear-gradient(180deg, #FF968D 0%, #FFBD92 100%)' }} className={clsx(styles['topnav'])}>
            <div className={styles['logo']}>
                <Link to="/">
                    <img src="/img/logo.png" alt="Logo" />
                </Link>
            </div>

            <div className={styles['links']}>
                <Link to="/about">About</Link>
                <Link to="/contact-us">Contact Us</Link>
                {
                    !user
                        ?
                        isLogin
                            ?
                            <Link to="/signup">Sign Up</Link>
                            :
                            <Link to="/login">Login</Link>
                        :
                        null
                }
                {
                    user
                        ?
                        <Link to="/" className={styles['avatar-link']}>
                            <div style={{ backgroundImage: `url('${getUserAvatar(user, false)}')` }} className={styles['avatar-img']} />
                            {getUserLongName(user)}
                        </Link>
                        :
                        null
                }
            </div>
        </div>
    );
}