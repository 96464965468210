export const PaginationNextIcon = ({ disabled }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
            <path d="M22.4927 45.3892C25.4463 45.4695 28.3868 44.9671 31.1464 43.9109C33.9059 42.8547 36.4303 41.2653 38.5756 39.2334C42.9081 35.13 45.4331 29.4735 45.5951 23.5083C45.7571 17.5431 43.5428 11.7579 39.4393 7.42536C37.4075 5.2801 34.973 3.55605 32.2749 2.35163C29.5768 1.14722 26.6678 0.486035 23.7142 0.40583C17.749 0.243849 11.9638 2.45816 7.63124 6.56163C3.29869 10.6651 0.773689 16.3216 0.611708 22.2868C0.531503 25.2404 1.03385 28.181 2.09006 30.9405C3.14628 33.7 4.73567 36.2245 6.76751 38.3697C8.79935 40.515 11.2338 42.239 13.932 43.4434C16.6301 44.6479 19.539 45.309 22.4927 45.3892ZM31.8558 32.1385L27.3575 32.0164L27.8461 14.023L32.3444 14.1452M13.8624 31.6499L14.351 13.6566L25.3526 22.9586" fill="url(#paint0_linear_126_551)" fillOpacity={!disabled ? 1 : 0.46} />
            <defs>
                <linearGradient id="paint0_linear_126_551" x1="22.4927" y1="45.3892" x2="23.7142" y2="0.40583" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF9A62" />
                    <stop offset="1" stop-color="#FF8A8C" />
                </linearGradient>
            </defs>
        </svg>
    );
}