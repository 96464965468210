.sticky-banner {
  position: absolute;
  top: 0px;
  left: 0px;

  z-index: -1;
}

.sticky-banner img {
  width: 100%;
}

.banner {
  display: flex;
  padding-left: 145px;

  position: relative;
}

.banner h1 {
  color: var(--Schemes-On-Primary, #FFF);
  font-family: Outfit;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
  line-height: 110px;

  margin-top: 77px;
}

.banner p {
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  white-space: nowrap;

  margin-top: 84px;
}

.banner .right {
  position: absolute;
  right: 0px;
}

.banner img {
  width: 774px;
}

.key-features {
  margin-top: 287px;

  position: relative;

  padding: 0px 187.500px 379px;
  ;
}

.key-features .floating-dot-1 {
  position: absolute;
  background-color: rgba(255, 186, 146, 0.29);
  width: 30px;
  height: 30px;
  left: 246px;
  bottom: 84px;
  border-radius: 100%;
}

.floating-feature-image {
  position: absolute;

  top: 363.5px;
  right: 0px;

  z-index: -1;
}

.key-features h2 {
  color: #000;
  font-family: Outfit;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  text-align: center;
}

.key-features .boxes {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 59px;
  row-gap: 112px;

  margin-top: 167px;
}

.key-features .box {
  border-radius: 43px;
  background: #F5F5F5;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);

  padding: 42px 27px 83px 40px;
}

.key-features .box.primary {
  background: radial-gradient(327.94% 106.08% at 88.32% 46.24%, #FFBB92 0%, #FF9A8E 100%);
}

.key-features .box .box-head {
  display: flex;
  align-items: center;
  gap: 15px;
}

.key-features .box .box-head h3 {
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.key-features .box .box-content {
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 32px;
}

.how-it-works {
  position: relative;
}


.how-it-works .heading {
  padding: 45.25px 0px 45.25px 850px;
  background: rgba(255, 186, 146, 0.29);
}

.how-it-works .heading h2 {
  color: #000;
  font-family: Outfit;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.how-it-works .contents {
  padding-left: 850px;

  margin-top: 77px;
}

.how-it-works .contents .list {
  display: flex;
  flex-direction: column;
  gap: 31px;
}

.how-it-works .contents .item {
  display: flex;
  align-items: center;
  gap: 59px;
}

.how-it-works .contents .item .point {
  color: #000;
  font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.how-it-works .floating-img {
  position: absolute;
  top: 0px;
  left: 0px;
}

.brands {
  position: relative;
  margin-top: 388px;

  background-color: #FFFFFF;
  padding: 114px 62px 136px 117px;

  display: flex;
  align-items: center;
  gap: 56px;
}

.brands .left {
  flex: 1;
}

.brands .tagline {
  color: #F28F02;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.brands h2 {
  color: #000;
  font-family: Outfit;
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  margin-top: 17px;
}

.brands p {
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  margin-top: 36px;

  max-width: 574px
}

.customer-voices {
  padding-bottom: 208px;
  padding-top: 231px;

  display: flex;
  flex-direction: column;
}

.customer-voices .title {
  position: relative;
  max-width: 743px;
  height: 395px;

  display: flex;
  flex-direction: column;
  /* align-items: center; */

  padding: 0px 136px 0px 108px;
  /* justify-content: center; */
}

.customer-voices .title img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;

  z-index: -1;
}

.customer-voices .title h2 {
  color: #000;
  font-family: Outfit;
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;

  margin-top: 74px;
}

.customer-voices .title div {
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  width: 490px;
}

.customer-voices .reviews {
  margin-top: 97px;
  display: flex;
  flex-direction: column;
  gap: 158px;
}

.how-it-works .floating-dot-1 {
  position: absolute;
  background-color: rgba(255, 186, 146, 0.29);
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  left: 674px;
  top: 195.31px;
  border-radius: 100%;
}

.how-it-works .floating-dot-2 {
  position: absolute;
  background-color: rgba(255, 186, 146, 0.29);
  width: 39px;
  height: 39px;
  flex-shrink: 0;
  left: 654px;
  top: 481.31px;
  border-radius: 100%;
}

.how-it-works .floating-dot-3 {
  position: absolute;
  background-color: rgba(255, 186, 146, 0.29);
  width: 39px;
  height: 39px;
  flex-shrink: 0;
  left: 453px;
  top: 642.31px;
  border-radius: 100%;
}

.how-it-works .floating-dot-4 {
  position: absolute;
  background-color: rgba(255, 186, 146, 0.29);
  width: 22px;
  height: 22px;
  right: 182px;
  top: 222.31px;
  border-radius: 100%;
}

.splide-nav-btn {
  padding: 0px !important;
  width: 47.811px !important;
  height: 49.654px !important;
}

.splide-nav-btn svg {
  width: 47.811px !important;
  height: 49.654px !important;
  transform: scaleX(1) !important;
}