.review-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
}

.user-avatar {
  position: absolute;
  top: -48.75px;
  left: -33.21;

  flex-shrink: 0;

  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
}

.user-avatar .bg-icon {
  position: absolute;
  top: 0px;
  left: 0px;

  width: 146px;
  height: 146px;

  background-image: url('../../../static/img/Ellipse 128.png');
  background-position: contain;
  background-repeat: no-repeat;

  z-index: -1;
}

.user-avatar .avatar-img {
  border-radius: 114px;
  width: 114px;
  height: 114px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.name-and-star {
  border-radius: 50px 51px 50px 50px;
  background: #FFF;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 35px 5px 152px;

  width: 100%;
}

.name-and-star h3 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.name-and-star .stars {
  display: flex;
  gap: 12.2px;
}

.date-and-count {
  border-radius: 50px 51px 50px 50px;
  background: #FFF;

  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);

  color: #000;
  font-family: "Josefin Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  margin-top: 4px;
  padding: 5px 24px;

  margin-left: 66px;

  display: flex;
  gap: 56px;
}

.review-content {
  border-radius: 0px 61px;
  background: #FFF;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-400) var(--sds-size-depth-negative-200) var(--sds-color-black-200), var(--sds-size-depth-0) var(--sds-size-depth-100) var(--sds-size-depth-100) var(--sds-size-depth-negative-100) var(--sds-color-black-100);

  padding: 24px 38px 39px 16px;
  margin-top: 42px;

  display: flex;
  align-items: stretch;
  gap: 19px;

  position: relative;
}

.review-content .line {
  border-left: 3px solid #000;
  /* background: #000; */
}

.review-content h4 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.review-content p {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;

  margin-top: 5.75px;
}

.review-like {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-color: #FDCBAD;
  border-radius: 100%;

  position: absolute;

  bottom: -20px;
  right: -20px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}