import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { companyFormSubmissionAsync } from "../../actions/general.action";
import styles from "./ContactUsPage.module.css";
import clsx from "clsx";
import { EnvelopeIcon } from "../../components/EnvelopeIcon/EnvelopeIcon";
import { showToast } from "../../utils";
import { TopNav } from "../../components/TopNav/TopNav";
import { Footer } from "../../components/Footer/Footer";

export const ContactUsPage = () => {
  const initialState = {
    first_name: '',
    email: '',
    phone: '',
    message: '',
  }

  const [state, setState] = useState(initialState);

  const dispatch = useDispatch();

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(companyFormSubmissionAsync(state, () => {
      setState(() => initialState)
      showToast('Message sent successfully', 'info')
    }, () => {
      // setSuccess(true)
    }))
  }

  useEffect(() => {
    document.documentElement.classList.add('light-bg')
    return () => document.documentElement.classList.remove('light-bg')
  }, [])

  useEffect(() => {
    document.title = "Cloud Reputation - Reach Out to us";
  }, []);

  return (
    <>
      <div className={styles['sticky-banner']}>
        <img src="/img/contact-us-banner.png" alt="Contact Us Banner" />
      </div>

      <TopNav />

      <div className={clsx(styles['banner'])}>
        <div className={styles['left']}>

          <h1>
            Still have a<br />
            <span>Question?</span>
          </h1>
          <p>We're here to help! Reach out with any questions,<br /> feedback, or inquiries</p>
        </div>
        <div className={styles['right']}>
          <img src="/img/contact-us-banner-image.png" alt="" />
        </div>

        <div className={styles['floating-dot-1']}></div>
        <div className={styles['floating-dot-2']}></div>
      </div>

      <div className={styles['send-message']}>
        <h2>Send us a Message</h2>
        <form onSubmit={onSubmit}>
          <input type="text" name="first_name" value={state.first_name} onChange={onChange} placeholder="Your Name" />
          <input type="text" name="email" value={state.email} onChange={onChange} placeholder="Email" />
          <input className={styles['block-input']} type="text" name="phone" value={state.phone} onChange={onChange} placeholder="Phone Number" />
          <textarea rows={5} className={styles['block-input']} name="message" value={state.message} onChange={onChange} id="" placeholder="Type your message"></textarea>

          <button onClick={onSubmit}>
            SUBMIT
          </button>
        </form>

        <div className={styles['for-queries']}>
          <h2>For Queries</h2>
          <div>
            <EnvelopeIcon />
            <span>admin@cloudreputation.io</span>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
