import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./SignupPage.module.css";
import { TopNav } from "../../components/TopNav/TopNav";
import { Footer } from "../../components/Footer/Footer";
import { registerAsync } from "../../actions/auth.action";
import { LockIcon } from "../../components/LockIcon/LockIcon";
import { UserIcon } from "../../components/UserIcon/UserIcon";

export const SignupPage = () => {
  const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  }
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    dispatch(registerAsync(state, (user) => {
      console.log(user)
      setState(initialState)
    }))
  }

  useEffect(() => {
    document.title = "Cloud Reputation - Sign Up";
  }, []);

  return (
    <>

      <TopNav bg="filled" />

      <div className={styles['login-form']}>

        <form>

          <div className={styles['form-group']}>
            <div className={styles['icon']}>
              <UserIcon />
            </div>
            <input type="text" name="first_name" value={state.first_name} onChange={onChange} placeholder="First Name" />
          </div>

          <div className={styles['form-group']}>
            <div className={styles['icon']}>
              <UserIcon />
            </div>
            <input type="text" name="last_name" value={state.last_name} onChange={onChange} placeholder="Last Name" />
          </div>

          <div className={styles['form-group']}>
            <div className={styles['icon']}>
              <UserIcon />
            </div>
            <input type="email" name="email" value={state.email} onChange={onChange} placeholder="Email ID" />
          </div>

          <div className={styles['form-group']}>
            <div className={styles['icon']}>
              <LockIcon />
            </div>
            <input type="password" name="password" value={state.password} onChange={onChange} placeholder="Password" />
          </div>

          <div className={styles['login-now']}>
            <button onClick={onSubmit}>Signup Now</button>
          </div>

        </form>
      </div>

      <Footer />
    </>
  );
};
