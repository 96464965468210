import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./Paginate.module.css";
import clsx from "clsx";
import { PaginationPrevIcon } from "../PaginationPrevIcon/PaginationPrevIcon";
import { PaginationNextIcon } from "../PaginationNextIcon/PaginationNextIcon";

export const Paginate = ({ page, last_page, setPage, className }) => {
    const [state, setState] = useState({
        min: 1,
        max: 1
    });

    useEffect(() => {
        let min = 1, max = page + 4;
        if (page >= 3) min = page - 2

        if (max - min > 4) max = min + 4

        if (max >= last_page) max = last_page

        if (max - min < 4) min = max - 4

        if (min < 1) min = 1

        setState({ min, max });
    }, [page, last_page]);

    const handleClick = useCallback(() => {
        return (x) => {
            return (event) => {
                event.preventDefault();
                setPage(x);
            }
        }
    }, [setPage])

    const links = useMemo(() => {
        let links = []
        const { min, max } = state;
        for (let x = min; x <= max; x++) {
            links.push(<a href="#" onClick={handleClick(x)} className={clsx(styles['bullet'], { [styles['active']]: x === page })}></a>)
        }
        return links;
    }, [state, handleClick])

    return (
        <div className={clsx(className, styles['paginate'])}>
            <a href="#" onClick={handleClick(page - 1)} className={clsx(styles['prev'], { [styles['disabled']]: page === 1 })}>
                <PaginationPrevIcon disabled={page === 1} />
            </a>
            <div className={styles['bullets']}>
                {links}
            </div>
            <a href="#" onClick={handleClick(page + 1)} className={clsx(styles['next'], { [styles['disabled']]: page === last_page })}>
                <PaginationNextIcon disabled={page === last_page} />
            </a>
        </div>
    )

    return links;
}