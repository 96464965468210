.sticky-banner {
  position: absolute;
  top: 0px;
  left: 0px;

  z-index: -1;
}

.sticky-banner img {
  width: 100%;
}

.banner {
  display: flex;
  padding-left: 145px;

  position: relative;

  margin-top: 55.6px;
}

.banner .floating-dot-1 {
  position: absolute;
  background-color: rgba(255, 190, 141, 0.31);
  width: 86px;
  height: 86px;
  left: 99px;
  top: 80px;
  border-radius: 100%;

  z-index: -1;
}

.banner .floating-dot-2 {
  position: absolute;
  background-color: rgba(255, 190, 141, 0.31);
  width: 266px;
  height: 266px;
  left: -143px;
  top: 338px;
  border-radius: 100%;

  z-index: -1;
}

.banner h1 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;

  margin-top: 123px;
}

.banner h1 span {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}

.banner p {
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  width: 458.588px;
  /* transform: rotate(0.597deg); */

  /* white-space: nowrap; */

  margin-top: 71px;
}

.banner .ellipse {
  width: 269px;
  height: 62px;
  flex-shrink: 0;

  border-radius: 269px;
  background: #FFF;

  color: #000;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  margin-top: 51.23px;

  border-width: 0px;

  background-image: url('../../../static/img/Ellipse\ 123.png');
  background-repeat: no-repeat;
  background-color: transparent;
}

.banner .right {
  position: absolute;
  right: 0px;
}

.banner img {
  width: 698px;
}

.our-mission {
  display: flex;
  /* align-items: center; */
  margin-top: 222px;

  padding: 0px 146.75px 0px 10px;

  position: relative;
}

.our-mission .floated-design {
  position: absolute;
  right: 0px;
  top: 120px;
}

.our-mission .left {
  margin-top: 78px;
}

.our-mission .left img {
  width: 670px;
}

.our-mission h2 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;

  text-align: center;
}

.our-mission p {
  color: #000;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  margin-top: 54px;
}

.stats {
  margin-top: 253.29px;
  padding: 44px 89px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #FFF;
}

.stats .value {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;

  text-align: center;
}

.stats .property {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;

  text-align: center;

  margin-top: 19px;
}

.why-trust-us {
  margin-top: 132px;
  margin-bottom: 348px;

  display: flex;

  position: relative;
  /* align-items: center; */
}

.why-trust-us .floated-design {
  position: absolute;
  left: 0px;
  top: 235px;
}

.why-trust-us .left {
  flex: 1;

  padding-top: 118px;
  padding-right: 120.75px;
  padding-left: 183px;
}

.why-trust-us h2 {
  color: #000;
  font-family: "Josefin Sans";
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
  text-align: center;
}

.why-trust-us p {
  color: #000;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  margin-top: 43px;
}

.why-trust-us .right img {
  width: 584px;
}