.paginate {
  display: flex;
  justify-content: space-between;
}

.bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.bullet {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: #D9D9D9;
}

.active {
  background-color: #C9C9C9;
}

.disabled {
  pointer-events: none;
}